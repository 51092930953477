<template>
    <div>
        <el-main style="padding: 0px">
            <el-form :label-position="labelPosition" :model="form" ref="deviceCmdForm" label-width="85px">
                <el-form-item label="动力舱门">
                    <el-row>
                        <el-col :span="1">
                            <div class="light" style="margin-top: 13px" :class="{greenLight:this.doForm.powerCabin}"></div>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-popconfirm title="该指令将【打开】动力舱门，是否继续？" @confirm="cmdCtrlSend(1, 1)">
                                <el-button slot="reference" size="mini" round type="danger" title="打开动力舱门" @click="">开</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="该指令将【关闭】动力舱门，是否继续？" @confirm="cmdCtrlSend(1, 0)">
                                <el-button slot="reference" size="mini" style="margin-left: 20px" round type="success" title="关闭动力舱门">关</el-button>
                            </el-popconfirm>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="电控舱门">
                    <el-row>
                        <el-col :span="1">
                            <div class="light" style="margin-top: 13px" :class="{greenLight:this.doForm.electricCabin}"></div>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-popconfirm title="该指令将【打开】电控舱门，是否继续？" @confirm="cmdCtrlSend(2, 1)">
                                <el-button size="mini" round type="danger" title="打开电控舱门" slot="reference">开</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="该指令将【关闭】电控舱门，是否继续？" @confirm="cmdCtrlSend(2, 0)">
                                <el-button size="mini" style="margin-left: 20px" round type="success" title="关闭电控舱门" slot="reference">关</el-button>
                            </el-popconfirm>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="设备舱门">
                    <el-row>
                        <el-col :span="1">
                            <div class="light" style="margin-top: 13px" :class="{greenLight:this.doForm.deviceCabin}"></div>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-popconfirm title="该指令将【打开】设备舱门，是否继续？" @confirm="cmdCtrlSend(3, 1)">
                                <el-button size="mini" round type="danger" title="打开设备舱门" slot="reference">开</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="该指令将【关闭】设备舱门，是否继续？" @confirm="cmdCtrlSend(3, 0)">
                                <el-button size="mini" style="margin-left: 20px" round type="success" title="关闭设备舱门" slot="reference">关</el-button>
                            </el-popconfirm>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="工控机侧门">
                    <el-row>
                        <el-col :span="1">
                            <div class="light" style="margin-top: 13px" :class="{greenLight:this.doForm.ipc}"></div>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-popconfirm title="该指令将【打开】工控机侧门，是否继续？" @confirm="cmdCtrlSend(4, 1)">
                                <el-button size="mini" round type="danger" title="打开工控机侧门" slot="reference">开</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="该指令将【关闭】工控机侧门，是否继续？" @confirm="cmdCtrlSend(4, 0)">
                                <el-button size="mini" style="margin-left: 20px" round type="success" title="关闭工控机侧门" slot="reference">关</el-button>
                            </el-popconfirm>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="电控舱风机">
                    <el-row>
                        <el-col :span="1">
                            <div class="light" style="margin-top: 13px" :class="{greenLight:this.doForm.electricFan}"></div>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-popconfirm title="该指令将【启动】电控舱风机，是否继续？" @confirm="cmdCtrlSend(5, 1)">
                                <el-button size="mini" round type="danger" title="启动电控舱风机" slot="reference">启</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="该指令将【停止】电控舱风机，是否继续？" @confirm="cmdCtrlSend(5, 0)">
                                <el-button size="mini" style="margin-left: 20px" round type="success" title="停止电控舱风机" slot="reference">停</el-button>
                            </el-popconfirm>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="排烟电磁阀">
                    <el-row>
                        <el-col :span="1">
                            <div class="light" style="margin-top: 13px" :class="{greenLight:this.doForm.solenoidValue}"></div>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-popconfirm title="该指令将【启动】排烟电磁阀，是否继续？" @confirm="cmdCtrlSend(7, 1)">
                                <el-button size="mini" round type="danger" title="启动排烟电磁阀" slot="reference">启</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="该指令将【停止】排烟电磁阀，是否继续？" @confirm="cmdCtrlSend(7, 0)">
                                <el-button size="mini" style="margin-left: 20px" round type="success" title="停止排烟电磁阀" slot="reference">停</el-button>
                            </el-popconfirm>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="水套加热">
                    <el-row>
                        <el-col :span="1">
                            <div class="light" style="margin-top: 13px" :class="{greenLight:this.doForm.waterHeatingStart}"></div>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-popconfirm title="该指令将【启动】水套加热，是否继续？" @confirm="cmdCtrlSend(8, 1)">
                                <el-button slot="reference" size="mini" round type="danger" title="启动水套加热">启</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="该指令将【停止】水套加热，是否继续？" @confirm="cmdCtrlSend(8, 0)">
                                <el-button slot="reference" size="mini" style="margin-left: 20px" round type="success" title="停止水套加热">停</el-button>
                            </el-popconfirm>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="备注：" prop="remark" :rules="{required: true, message: '请填写指令操作备注', trigger: 'blur'}">
                    <el-row>
                        <el-col :span="5">
                            <el-input
                                class="remark"
                                v-model="form.remark"
                                type="textarea"
                                maxlength="300"
                                autocomplete="off"
                                placeholder="请填写指令操作备注">
                            </el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
        </el-main>
    </div>
</template>

<script>
import {deviceControl, queryDIDOInfoData} from "@/api/station/deviceInfoApi";
import {getToken} from "@/api/auth";
import message from "@/utils/message";
import loading from "@/utils/cmdLoading";
import websocket from "@/utils/websocket";
export default {
    name: "device-cmd",
    props: {

        deviceId: {

            type: Number
        },
        deviceNumber: {

            type: String
        }
    },
    data() {

        return {
            labelPosition: 'left',
            topic: "/user/" + getToken() + "/deviceCmd",
            doForm: {

                powerCabin: true,
                electricCabin: true,
                deviceCabin: true,
                ipc: true,
                electricFan: true,
                dgenSet: true,
                solenoidValue: true,
                waterHeatingStart: true
            },
            wsCtrlUrl: '',
            cmdLoading: null,
            form: {
                // 备注
                remark: ''
            }

        }
    },
    methods: {

        // 初始化页面
        initPage() {

            this.unSub();
            this.queryPageData();
            websocket.initWebSocket(this.topic, this.wsCallback);
        },
        // 查询页面数据
        queryPageData() {

            queryDIDOInfoData({

                deviceNum: this.deviceNumber
            }).then((res) => {

                this.doForm = res.data.doForm;
            }).catch(err => console.error(err));
        },
        // websocket回调方法
        wsCallback() {

            if (this.cmdLoading) {

                this.cmdLoading.close();
            }
            message.success("指令下发成功");
            this.queryPageData();
        },
        // 发送指令
        cmdCtrlSend(deviceType, commandIndex) {

            this.$refs["deviceCmdForm"].validate((valid) => {

                if (valid) {

                    let param = {

                        deviceNumber: this.deviceNumber,
                        deviceWholeId: this.deviceId + "",
                        deviceType: deviceType + "",
                        commandIndex : commandIndex + "",
                        remark: this.form.remark
                    };
                    deviceControl({message: JSON.stringify(param)}).then((res) => {

                        if (res.code === '100') {

                            this.cmdLoading = loading.loading("指令下发中...", 60);
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err => console.error(err));
                } else {

                    message.warning("请填写指令操作备注")
                }
            });
        },
        unSub() {

            websocket.unSubTopic(this.topic);
        }
    }
}
</script>

<style scoped>

.remark {

    width: 780px;
}

</style>