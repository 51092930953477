<template>
    <div>
        <el-tabs :tab-position="tabPosition" v-model="activeName" @tab-click="handleClick" :before-leave="tabLevel">
            <el-tab-pane label="指令下发" name="deviceCtrlTab">
                <DeviceCmd class="tab-padding-class" ref="deviceCtrlPage" :deviceId="deviceId" :deviceNumber="deviceNumber"></DeviceCmd>
            </el-tab-pane>
            <el-tab-pane label="柴发设置" name="deviceDgSetTab">
                <DeviceDgSet class="tab-padding-class" ref="deviceDgSetPage" :deviceId="deviceId" :deviceNumber="deviceNumber"></DeviceDgSet>
            </el-tab-pane>
            <el-tab-pane label="参数设置" name="deviceParamSetTab">
                <DeviceParamSet ref="deviceParamSetPage" :deviceId="deviceId" :deviceNumber="deviceNumber"></DeviceParamSet>
            </el-tab-pane>
            <el-tab-pane label="模块设置" name="devicePowerConverterSetTab">
                <DevicePowerConverterSet class="tab-padding-class" ref="devicePowerConverterSetPage" :deviceId="deviceId" :deviceNumber="deviceNumber"></DevicePowerConverterSet>
            </el-tab-pane>
            <el-tab-pane label="电池设置" name="deviceOpsStatusSetTab">
                <DeviceOpsStatusSet class="tab-padding-class" ref="deviceOpsStatusSetPage" :deviceId="deviceId" :deviceNumber="deviceNumber"></DeviceOpsStatusSet>
            </el-tab-pane>
            <el-tab-pane label="策略设置" name="deviceStrategySetTab">
                <DeviceStrategySet class="tab-padding-class" ref="deviceStrategySetPage" :deviceId="deviceId" :deviceNumber="deviceNumber"></DeviceStrategySet>
            </el-tab-pane>
            <el-tab-pane label="远程升级" name="deviceUpgradeTab">
                <DeviceUpgrade class="tab-padding-class" ref="deviceUpgradePage" :deviceId="deviceId" :deviceNumber="deviceNumber"></DeviceUpgrade>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    import DeviceCmd from '@/views/station/device/device-cmd/device-cmd.vue';
    import DeviceParamSet from '@/views/station/device/device-cmd/device-param-set.vue';
    import DeviceOpsStatusSet from '@/views/station/device/device-cmd/device-ops-status-set.vue';
    import DeviceStrategySet from '@/views/station/device/device-cmd/device-strategy-set.vue';
    import DeviceUpgrade from '@/views/station/device/device-cmd/device-upgrade.vue';
    import DevicePowerConverterSet from '@/views/station/device/device-cmd/device-power-converter-set.vue';
    import DeviceDgSet from '@/views/station/device/device-cmd/device-dg-set.vue';
    export default {

        name: 'device-cmd-index',
        components: {

            DevicePowerConverterSet,
            DeviceCmd,
            DeviceParamSet,
            DeviceOpsStatusSet,
            DeviceStrategySet,
            DeviceUpgrade,
            DeviceDgSet
        },
        data() {

            return {

                titleName: '',
                tabPosition: 'left',
                activeName: 'deviceCtrlTab',
                deviceId: null,
                deviceNumber: ''
            }
        },
        methods: {

            // 初始化指令控制页面
            initPage(id, number) {

                this.deviceId = id;
                this.deviceNumber = number;
                this.$nextTick(() => {

                    this.$refs[this.activeName.replace('Tab', 'Page')].initPage();
                })
            },
            // 处理标签页点击事件
            handleClick(tab, event) {

                this.$refs[tab.name.replace('Tab', 'Page')].initPage();
            },
            // tab切换
            tabLevel(activeName, oldActiveName) {

                this.$refs[oldActiveName.replace('Tab', 'Page')].unSub();
            }
        },
        beforeDestroy() {

            // 关闭前取消当前tab页的订阅
            this.$refs[this.activeName.replace('Tab', 'Page')].unSub();
        }
    }
</script>
<style scoped lang="scss">
    .tab-padding-class {

        margin-left: 5%;
    }
</style>
