<template>
    <div class="opsStatusSetClass">
        <el-main style="padding: 0">
            <el-alert
                :closable="false"
                title="该功能会向设备下发设置电池状态的指令，下发成功后会同时影响服务端与设备端计算电池平均SOC的值。"
                description="备注：该指令并不会影响设备端电池是否运行，只涉及服务端与设备端电池平均SOC的计算。"
                type="warning"
                class="alert"
            >
            </el-alert>
            <span class="label1">电池名称</span><span class="label2">是否参与电池平均SOC计算</span>
            <span class="label3">电池名称</span><span class="label4">是否参与电池平均SOC计算</span>
            <div class="divider">
                <el-divider></el-divider>
            </div>
            <el-form label-width="130px" label-position="left" :inline="true" style="width: 800px;">
                <el-form-item class="formItem" v-for="(item, index) in newBatTableStatus" :key="item.index" :label="'电池包' + item.index">
                    <el-radio-group v-model="newBatTableStatus[index].status" size="mini">
                        <el-radio-button label=1>是</el-radio-button>
                        <el-radio-button label="0">否</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="form" ref="remarkForm" class="form">
                <el-form-item label="备注：" prop="remark" :rules="{required: true, message: '请填写操作备注', trigger: 'blur'}">
                    <el-input
                        class="remark"
                        v-model="form.remark"
                        type="textarea"
                        maxlength="300"
                        autocomplete="off"
                        placeholder="请填写操作备注">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-button size="mini" type="primary" @click="sendCmd" :disabled="disableBtn" style="margin-top: 10px;margin-left: 20px">设置</el-button>
        </el-main>
    </div>
</template>
<script>
    import {deviceOpStatusOperate} from "@/api/station/deviceInfoApi";
    import {getToken} from "@/api/auth";
    import websocket from "@/utils/websocket";
    import loading from "@/utils/cmdLoading";
    import message from "@/utils/message";
    export default {
        name: "device-ops-status-set",
        props: {

            deviceId: {

                type: Number
            },
            deviceNumber: {

                type: String
            }
        },
        data() {

            return {

                newBatTableStatus: [
                    {index: "1", status: "1"},
                    {index: "2", status: "1"},
                    {index: "3", status: "1"},
                    {index: "4", status: "1"},
                    {index: "5", status: "1"},
                    {index: "6", status: "1"},
                    {index: "7", status: "1"},
                    {index: "8", status: "1"},
                    {index: "9", status: "1"},
                    {index: "10", status: "1"},
                ],
                oldBatTableStatusStr: "",
                labelPosition: 'left',
                disableBtn: false,
                topic: "/user/" + getToken() + "/deviceOpsStatusSet",
                cmdLoading: null,
                form: {

                    remark: ''
                }
            }
        },
        methods: {

            // 初始化页面
            initPage() {

                this.unSub();
                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                // 参数未回来之前禁用
                this.disableBtn = true;
                websocket.initWebSocket(this.topic, this.wsCallback);
                let param = {

                    cmdCode: '0835',
                    deviceNumber: this.deviceNumber,
                    deviceWholeId: this.deviceId,
                    assemblyType: 1
                };
                deviceOpStatusOperate({message: JSON.stringify(param)}).then((res) => {

                    if (res.code === '100') {

                        this.cmdLoading = loading.loading("电池包状态读取中...", 60);
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => console.error(err));
            },
            wsCallback(msg) {

                if (this.cmdLoading) {

                    this.cmdLoading.close();
                }
                let data = JSON.parse(msg.body);
                let code = data.instructionCode;
                // 指令码为0x0935 = 2357 读取参数响应
                if (code == "2357") {

                    // 渲染页面数据
                    message.success("电池包状态读取成功");
                    let result = data.messageBody.devOpsStatus;
                    let batStatusList = result.statusList;
                    for (let i = 0; i < batStatusList.length; i++) {

                        this.newBatTableStatus[i].status = batStatusList[i] + "";
                    }
                    this.oldBatTableStatusStr = JSON.stringify(this.newBatTableStatus);
                    this.disableBtn = false;
                }
                // 指令码为0x0936 = 2358 设置状态响应成功则需要将当前设置成功的值放入oldBatTableStatus中
                // 防止在页面上进行连续修改时,提示不正确的问题
                if (code == "2358") {

                    message.success("电池包状态设置成功");
                    this.oldBatTableStatusStr = JSON.stringify(this.newBatTableStatus);
                }
            },
            // 发送设置参数指令
            sendCmd() {

                console.error("新的值：" + JSON.stringify(this.newBatTableStatus));
                console.error("旧的值：" + this.oldBatTableStatusStr);
                if (JSON.stringify(this.newBatTableStatus) === this.oldBatTableStatusStr) {

                    message.warning('内容未发生变化，无需提交')
                    return;
                }
                this.$refs["remarkForm"].validate((valid) => {

                    if (valid) {

                        let statusArr = [];
                        for (let i = 0; i < 10; i++) {

                            statusArr[i] = parseInt(this.newBatTableStatus[i].status);
                        }
                        let param = {

                            cmdCode: '0836',
                            deviceNumber: this.deviceNumber,
                            deviceWholeId: this.deviceId,
                            assemblyType: 1,
                            statusArr: statusArr.reverse(),
                            remark: this.form.remark
                        };
                        deviceOpStatusOperate({message: JSON.stringify(param)}).then((res) => {

                            if (res.code === '100') {

                                this.cmdLoading = loading.loading("电池包状态设置中...", 60);
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => console.error(err));
                    }
                });
            },
            unSub() {

                websocket.unSubTopic(this.topic);
            }
        }
    }
</script>
<style scoped="scoped">
    div::-webkit-scrollbar {
        width: 10px;
    }
    .opsStatusSetClass {

        width: 1150px;
        height: 550px;
        overflow-y: auto;
    }
    .alert {

        margin-bottom: 30px;
        width: 1050px
    }
    .remark {

        width: 780px;
    }
    .label1 {

        margin: 0 0 0 20px;
    }
    .label2 {

        margin: 0 0 0 40px;
    }
    .label3 {

        margin: 0 0 0 98px;
    }
    .label4 {

        margin: 0 0 0 40px;
    }
    .form {

        margin-top: 10px;
        margin-left: 15px;
    }
    .formItem {

        margin: 0 120px 0 25px;
    }
    .divider {

        width: 670px;
        margin-top: -15px;
        margin-bottom: -20px;
    }
</style>