<template>
    <div class="powerConverterSetClass">
        <el-main>
            <el-alert
                :closable="false"
                title="该功能不涉及指令下发，仅用作服务端校准光伏/整流模块数据使用。"
                description="备注：由于设备端不能检测光伏模块损坏的情况。如光伏模块2损坏，那么光伏模块3的数据将上报至光伏模块2的位置上，故在服务端进行此设置，用于校准数据。"
                type="warning" style="margin-bottom: 30px; width: 1050px">
            </el-alert>
            <el-table size="mini" :data="devicePowerConverterPartsInfo">
                <el-table-column label="模块名称" width="100" prop="majorClassName">
                    <template slot-scope="scope">
                        {{scope.row.majorClassName}}{{scope.row.serialNumber}}
                    </template>
                </el-table-column>
                <el-table-column label="当前状态" align="center" width="150px">
                    <template slot-scope="scope">
                        <el-radio-group v-model="scope.row.status" size="mini">
                            <el-radio-button label="0">启用</el-radio-button>
                            <el-radio-button label="1">停用</el-radio-button>
                        </el-radio-group>
                    </template>
                </el-table-column>
            </el-table>
            <el-form :model="form" ref="remarkForm" class="form">
                <el-form-item label="备注：" prop="remark" :rules="{required: true, message: '请填写操作备注', trigger: 'blur'}">
                    <el-input
                        class="remark"
                        v-model="form.remark"
                        type="textarea"
                        maxlength="300"
                        autocomplete="off"
                        placeholder="请填写操作备注">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-button size="mini" type="primary" @click="updatePartStatus()" style="margin-top: 15px">设置</el-button>
        </el-main>
    </div>
</template>

<script>
import {queryDevicePartsInfoById, updatePartStatus} from "@/api/station/deviceInfoApi";
import message from "@/utils/message";
export default {
    name: "device-power-converter-set",
    props: {

        deviceId: {

            type: Number
        },
        deviceNumber: {

            type: String
        }
    },
    data() {

        return {

            devicePowerConverterPartsInfo: [],
            form: {

                remark: ''
            }
        }
    },
    methods: {

        /**
         * 初始化页面.
         */
        initPage() {

            this.devicePowerConverterPartsInfo = [];
            queryDevicePartsInfoById({id : this.deviceId}).then((res) => {

                let data = res.data;
                for (let i = 0; i < data.length; i++) {

                    let prefix = data[i].prefix;
                    if (prefix == "GF") {

                        this.devicePowerConverterPartsInfo.push(data[i]);
                    } else if (prefix == "ZL"){

                        this.devicePowerConverterPartsInfo.push(data[i]);
                    }
                }
            });
        },
        /**
         * 更新组件状态.
         * @param row组件信息
         */
        updatePartStatus() {

            this.$refs["remarkForm"].validate((valid) => {

                if (valid) {

                    updatePartStatus({

                        param: this.devicePowerConverterPartsInfo,
                        remark: this.form.remark,
                        deviceWholeId: this.deviceId
                    }).then((res) => {

                        if (res.code === 200) {

                            message.success("设置成功！")
                            this.initPage();
                        } else {

                            message.error("设置失败！")
                        }
                    });
                }
            });
        },
        unSub() {


        }
    }
}
</script>

<style scoped>
div::-webkit-scrollbar {
    width: 10px;
}
.powerConverterSetClass {

    width: 1150px;
    height: auto;
    overflow-y: auto;
}
.remark {

    width: 780px;
}
.form {

    margin-top: 10px;
}
</style>